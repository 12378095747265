import React, {createContext, Dispatch, Reducer, useContext, useReducer} from "react";
import {Actions} from "../index";

export enum COUNTRY {
    US = 'United States',
    KR = '대한민국',
    CL = 'Chile',
}

export enum LANGUAGE {
    ENGLISH = 'English',
    KOREAN = '한국어',
    SPANISH = 'Spanish',
}

export type LanguageCountry = {
    language: LANGUAGE;
    country: COUNTRY;
}

export type SupportedLanguage = {
    key: string;
    languageCountry: LanguageCountry;
}

export const initialSupportedLanguage = {
    key: 'KR',
    languageCountry: {language: LANGUAGE.KOREAN, country: COUNTRY.KR}
};

export const SUPPORTED_LANGUAGES: Array<SupportedLanguage> = [
    {key: 'US', languageCountry: {language: LANGUAGE.ENGLISH, country: COUNTRY.US}},
    {key: 'KR', languageCountry: {language: LANGUAGE.KOREAN, country: COUNTRY.KR}},
    {key: 'CL', languageCountry: {language: LANGUAGE.SPANISH, country: COUNTRY.CL}}
];

export type LanguageUnit = {
    language: LANGUAGE;
    country: COUNTRY;
    key: string;
    value: string;
}

export const initialLanguageUnit: LanguageUnit = {
    language: LANGUAGE.ENGLISH,
    country: COUNTRY.US,
    key: '',
    value: ''
};

export const initialLanguageSet: LanguageSet = {
    ip: initialSupportedLanguage,
    browser: initialSupportedLanguage,
    user: initialSupportedLanguage
};

type LanguageSet = {
    ip: SupportedLanguage;
    browser: SupportedLanguage;
    user: SupportedLanguage;
}

type Props = {
    reducer: Reducer<LanguageSet, Actions>;
    initState: LanguageSet;
}

type ContextProps = {
    languageState: LanguageSet;
    languageDispatch: Dispatch<Actions>;
}

export const LanguageContext: React.Context<ContextProps> = createContext({} as ContextProps);

export const LanguageProvider: React.FC<Props> =
    ({reducer, initState, children}) => {
        const [languageState, languageDispatch] = useReducer(reducer, initState);
        const value = {languageState, languageDispatch};
        return (
            <LanguageContext.Provider value={value}>
                {children}
            </LanguageContext.Provider>
        );
    };

export const useLanguageStateValue = () => useContext(LanguageContext);

const LanguageController: React.FC = ({children}) => {
    const reducer: Reducer<LanguageSet, Actions> = (state, action) => {
        switch (action.type) {
            case 'flush':
                return initialLanguageSet;
            case 'setLanguages':
                return {
                    ...state,
                    ip: action.value.ip,
                    browser: action.value.browser,
                    user: action.value.user
                };
            case 'setUserLanguage':
                return {
                    ...state,
                    user: {
                        key: action.value.key,
                        languageCountry: {
                            language: action.value.languageCountry.language,
                            country: action.value.languageCountry.country
                        }
                    }
                };
            default:
                return state;
        }
    }
    return (
        <LanguageProvider reducer={reducer} initState={initialLanguageSet}>
            {children}
        </LanguageProvider>
    );
}

export default LanguageController;
