import React, {lazy, Suspense, useEffect, useState} from 'react';
import {Route, Switch} from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import CustomBackdrop from "./components/molecules/CustomBackdrop";

const Home = lazy(() => import("./components/pages/Home"));
const MyAccount = lazy(() => import('./components/pages/MyAccount'));
const Dashboard = lazy(() => import("./components/pages/Dashboard"));
const ProductRegister = lazy(() => import("./components/pages/Products/ProductRegister"));
const Products = lazy(() => import('./components/pages/Products'));
const Orders = lazy(() => import("./components/pages/Orders"));
const Auth = lazy(() => import("./components/pages/Auth"));
const SignUpForm = lazy(() => import("./components/pages/SignUp/SignUpForm"));
const SignUpStoreInformation = lazy(() => import("./components/pages/SignUp/SignUpStoreInformation"));
const SignUpComplete = lazy(() => import("./components/pages/SignUp/SignUpComplete"));
const FindId = lazy(() => import("./components/pages/FindId"));
const FindPassword = lazy(() => import("./components/pages/FindPassword"));
const SignUpAgreements = lazy(() => import("./components/pages/SignUp/SignUpAgreements"));
const ProductEdit = lazy(() => import("./components/pages/Products/ProductEdit"));
const Campaign = lazy(() => import("./components/pages/Campaign"));

const App: React.FC = () => {

    const mqList = [window.matchMedia('(min-width: 1224px)'), window.matchMedia('(min-height: 768px)')];
    const [horizontal, setHorizontal] = useState(mqList[0].matches);
    const [vertical, setVertical] = useState(mqList[1].matches);

    useEffect(() => Aos.init({duration: 1500}), []);

    useEffect(() => {
        mqList[0].addEventListener('change', (e) => setHorizontal(e.matches));
        mqList[1].addEventListener('change', (e) => setVertical(e.matches));
        return () => {
            mqList[0].removeEventListener('change', (e) => setHorizontal(e.matches));
            mqList[1].removeEventListener('change', (e) => setVertical(e.matches));
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [horizontal, vertical]);

    function setDocHeight() {
        const basePercentMax = Math.max(window.innerHeight, window.innerWidth) / 100;
        const basePercentMin = Math.min(window.innerHeight, window.innerWidth) / 100;
        document.documentElement.style.setProperty('--vw', `${horizontal ? window.innerWidth / 100 : 12.24}px`);
        document.documentElement.style.setProperty('--vh', `${vertical ? window.innerHeight / 100 : 7.68}px`);
        document.documentElement.style.setProperty('--vmax', `${(horizontal || vertical) && basePercentMax > 12.25 ? basePercentMax : 12.24}px`);
        document.documentElement.style.setProperty('--vmin', `${(horizontal || vertical) && basePercentMin > 7.69 ? basePercentMin : 7.68}px`);
        document.documentElement.style.setProperty('--em', `${(horizontal || vertical) && basePercentMax > 12.25 ? basePercentMax * 0.5 + 6 : 12}px`);
    }

    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);

    setDocHeight();

    return (
        <Suspense fallback={<CustomBackdrop show={true}/>}>
            <Switch>
                <Route exact path={'/'}
                       children={<Home/>}/>
                <Route exact path={'/home'}
                       children={<Home/>}/>
                <Route exact path={'/auth'}
                       children={<Auth/>}/>
                <Route exact path={'/my-account'}
                       children={<MyAccount/>}/>
                <Route exact path={'/find-id'}
                       children={<FindId/>}/>
                <Route exact path={'/find-password'}
                       children={<FindPassword/>}/>
                <Route exact path={'/sign-up/agreements'}
                       children={<SignUpAgreements/>}/>
                <Route exact path={'/sign-up/form'}
                       children={<SignUpForm/>}/>
                <Route exact path={'/sign-up/store-information'}
                       children={<SignUpStoreInformation/>}/>
                <Route exact path={'/sign-up/complete'}
                       children={<SignUpComplete/>}/>
                <Route exact path={'/dashboard'}
                       children={<Dashboard/>}/>
                <Route exact path={'/products'}
                       children={<Products/>}/>
                <Route exact path={'/products/register'}
                       children={<ProductRegister/>}/>
                <Route exact path={'/product/edit/:productId'}
                       children={(render) => <ProductEdit productId={render.match?.params.productId}/>}/>
                <Route exact path={'/orders'}
                       children={<Orders/>}/>
                <Route exact path={'/campaign'}
                       children={<Campaign/>}/>
            </Switch>
        </Suspense>
    );
};

export default App;
