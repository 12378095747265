import React from "react";
import {Backdrop, CircularProgress, withStyles} from "@material-ui/core";

const LimitedBackdrop = withStyles({
    root: {
        position: "absolute",
        zIndex: 1,
        backgroundColor: 'rgba(170, 170, 170, 0.5)'
    }
})(Backdrop);

type Props = {
    show: boolean;
}

const CustomBackdrop: React.FC<Props> = (props: Props) => {

    const {show} = props;

    return (
        <LimitedBackdrop open={show}>
            <CircularProgress variant="indeterminate" style={{strokeLinecap: 'round'}} size={40} thickness={4}/>
        </LimitedBackdrop>
    );
};

export default CustomBackdrop;
