import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import LanguageController from "./contexts/Language";
import {RecoilRoot} from "recoil";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {createBrowserHistory} from "history";

const history = createBrowserHistory();

Sentry.init({
    dsn: "https://b95cbf14e9724dbfbd338c4f1af21d04@o915266.ingest.sentry.io/5879089",
    integrations: [new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.Fragment>
        <RecoilRoot>
            <LanguageController>
                    <Router history={history}>
                        <App/>
                    </Router>
            </LanguageController>
        </RecoilRoot>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
